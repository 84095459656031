import st from './Documents.module.css';
import { v4 as uuidv4 } from 'uuid';
import { PDFPreview } from './components/PDFPreview/PDFPreview';
// эти импорты стилей исключают предупреждение консоли о не найденных стилях для pdf
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
// =========================================================
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { fetchDocsList } from 'src/store/docs/getDocs';
import { Helmet } from 'react-helmet-async';

export const Documents = () => {
  // сортированный массив документов
  const [docsListSort, setDocsListSort] = useState([]);

  const { documentList } = useSelector(state => state.docsListReducer.dataDocsList);
  const { status } = useSelector(state => state.docsListReducer);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!documentList && status !== 'failed') {
      dispatch(fetchDocsList());
    }

    if (status === 'success') {
      // сортируем  массив по ключу "slideOrder" (по порядковому номеру слайда)
      setDocsListSort(Array.from(documentList).sort((a, b) => {
        return (a.documentordernum > b.documentordernum) - 0.5;
      }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, status]);



  return (
    <>
      <Helmet>
        <title>Документы | LIFTUP</title>
        <meta
          name="description"
          content="Документы, научные статьи и презентации."
        />
      </Helmet>

      <main className={`${st.main} container`}>
        <h1 className={st.title}>Документы</h1>

        <div className={st.flashlight2}></div>

        <ul className={st.docs_list}>
          {status === 'success' ?
            docsListSort.map(item => (
              <li className={st.item_li} key={uuidv4()}>
                <Link to={`${item.documentlink}`} target='_blank'>
                  <div className={st.item_fence}>
                    <PDFPreview documentlink={item.documentlink} />
                  </div>
                </Link>
                <p className={st.docs_item_p}>{item.documentname}</p>
              </li>
            )) :
            <p className={st.preloader}>Загрузка документов...</p>
          }
        </ul>
      </main>
    </>
  )


  // НЕ УДАЛТЬ!!! альтернативный вариант
  // return (
  //   <main className={`${st.main} container`}>
  //     <h1 className={st.title}>Документы</h1>

  //     <ul className={st.docs_list}>

  //       {documents_list.map(item => (
  //         <li className={st.docs_item_forobject} key={uuidv4()}>
  //           <object
  //             className={st.object}
  //             data={item.docLink}
  //             type='application/pdf'>
  //           </object>

  //           <p className={st.docs_item_forobject_p}>{item.docTitle}</p>
  //         </li>
  //       ))}

  //     </ul>
  //   </main>
  // )

};
