import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Home } from 'src/pages/Home/Home.jsx';
import { About } from 'src/pages/About/About.jsx';
import { Layout } from 'src/components/Layout/Layout.jsx';
import { AdminPanel } from 'src/pages/AdminPanel/AdminPanel.jsx';
import { DeptMain } from 'src/pages/AdminPanel/components/DeptMain/DeptMain.jsx';
import { DeptNews } from 'src/pages/AdminPanel/components/DeptNews/DeptNews.jsx';
import { DeptDocs } from 'src/pages/AdminPanel/components/DeptDocs/DeptDocs.jsx';
import { DeptOurClients } from 'src/pages/AdminPanel/components/DeptOurClients/DeptOurClients.jsx';
import { DeptService } from 'src/pages/AdminPanel/components/DeptService/DeptService.jsx';
import { DeptClBase } from 'src/pages/AdminPanel/components/DeptClBase/DeptClBase.jsx';
import { DeptEquipment } from 'src/pages/AdminPanel/components/DeptEquipment/DeptEquipment.jsx';
import { DeptLiftDown } from 'src/pages/AdminPanel/components/DeptLiftDown/DeptLiftDown.jsx';
import { News } from 'src/pages/News/News.jsx';
import { NewsLists } from './pages/News/components/NewsLists/NewsLists.jsx';
import { NewsRead } from 'src/pages/News/components/NewsRead/NewsRead.jsx';
import { Documents } from './pages/Documents/Documents.jsx';
import { Analytics } from './pages/Analytics/Analytics.jsx';
import { Clients } from './pages/Clients/Clients.jsx';
import { ServiceCenters } from './pages/ServiceCenters/ServiceCenters.jsx';
import { SignIn } from './pages/SignIn/SignIn.jsx';
import { AuthorForm } from './pages/SignIn/components/AuthorForm/AuthorForm.jsx';
import { RegForm } from './pages/SignIn/components/RegForm/RegForm.jsx';
import { fetchUser } from './store/authorization/auth.js';
import { ScrollToTop } from './components/ScrollToTop/ScrollToTop.jsx';
import { PassRecPhone } from './pages/SignIn/components/PassRecPhone/PassRecPhone.jsx';
import { PassRecCode } from './pages/SignIn/components/PassRecCode/PassRecCode.jsx';
import { NewPassword } from './pages/SignIn/components/NewPassword/NewPassword.jsx';
import { PassChangeSuccess } from './pages/SignIn/components/PassChangeSuccess/PassChangeSuccess.jsx';
import { RegPhone } from './pages/SignIn/components/RegPhone/RegPhone.jsx';
import { RegCode } from './pages/SignIn/components/RegCode/RegCode.jsx';
import { AccountPersonal } from './pages/AccountPersonal/AccountPersonal.jsx';
import { LkAboutCompany } from './pages/AccountPersonal/components/LkAboutCompany/LkAboutCompany.jsx';
import { LkEquipment } from './pages/AccountPersonal/components/LkEquipment/LkEquipment.jsx';
import { LkDocs } from './pages/AccountPersonal/components/LkDocs/LkDocs.jsx';
import { LkLiftDown } from './pages/AccountPersonal/components/LkLiftDown/LkLiftDown.jsx';
import { Error404 } from './components/Error404/Error404.jsx';
import { RouteForAuthUsers } from './components/RouteForAuthUsers/RouteForAuthUsers.jsx';
import { RouteForAdmin } from './components/RouteForAdmin/RouteForAdmin.jsx';
import { PrivacyPolicy } from './pages/PrivacyPolicy/PrivacyPolicy.jsx';
import { YandexMetrica } from './components/YandexMetrica/YandexMetrica.jsx';
import { HelmetProvider } from 'react-helmet-async';

const App = () => {
  const dispatch = useDispatch();

  // достаем токен из localStorage
  const token = localStorage.getItem('tokenlu');

  useEffect(() => {
    // запускается при загрузке сайта, проверяет token и, если он есть, 
    // запускает получение данных user-а. и диспатчит их в store
    // если token-а нет, то продолжается работа без авторизации
    if (token !== null && token !== '') {
      dispatch(fetchUser());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);


  return (
    <HelmetProvider>
      <BrowserRouter>
        <YandexMetrica />

        <ScrollToTop />

        <Routes>
          <Route path='/' element={<Layout />}>
            <Route index element={<Home />} />

            <Route element={<RouteForAdmin />}>
              <Route path='superlift' element={<AdminPanel />}>
                <Route path='main' element={<DeptMain />} />
                <Route path='news' element={<DeptNews />} />
                <Route path='docs' element={<DeptDocs />} />
                <Route path='ourclients' element={<DeptOurClients />} />
                <Route path='service' element={<DeptService />} />
                <Route path='clientbase' element={<DeptClBase />} />
                <Route path='equipment/:id' element={<DeptEquipment />} />
                <Route path='liftdown' element={<DeptLiftDown />} />
              </Route>
            </Route>

            <Route element={<RouteForAuthUsers />}>
              <Route path='lk/:id' element={<AccountPersonal />}>
                <Route path='aboutcompany' element={<LkAboutCompany />} />
                <Route path='equip' element={<LkEquipment />} />
                <Route path='docs' element={<LkDocs />} />
                <Route path='liftdown' element={<LkLiftDown />} />
              </Route>
            </Route>

            <Route path='about' element={<About />} />

            <Route path='news' element={<News />}>
              <Route path='newslists' element={<NewsLists />} />
              <Route path='newsread/:id' element={<NewsRead />} />
            </Route>

            <Route path='documents' element={<Documents />} />

            <Route path='analytics' element={<Analytics />} />

            <Route path='clients' element={<Clients />} />

            <Route path='servicecenters' element={<ServiceCenters />} />

            <Route path='privacypolicy' element={<PrivacyPolicy />} />

            <Route path='*' element={<Error404 />} />
          </Route>

          <Route path='signin' element={<SignIn />}>
            <Route path='author' element={<AuthorForm />} />
            <Route path='passrecphone' element={<PassRecPhone />} />
            <Route path='passreccode' element={<PassRecCode />} />
            <Route path='newpassword' element={<NewPassword />} />
            <Route path='passchangesuccess' element={<PassChangeSuccess />} />
            <Route path='reg' element={<RegForm />} />
            <Route path='regphone' element={<RegPhone />} />
            <Route path='regcode' element={<RegCode />} />
          </Route>

        </Routes>
      </BrowserRouter>
    </HelmetProvider>
  )
};

export default App;
