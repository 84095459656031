import { Helmet } from 'react-helmet-async';
import st from './Analytics.module.css';

export const Analytics = () => {

  return (
    <>
      <Helmet>
        <title>Аналитика компании | LIFTUP</title>
        <meta
          name="description"
          content="Графики продаж и сервисного обслуживания компании | LIFTUP"
        />
      </Helmet>

      <main className={`${st.analytics} container`}>
        <h1 className={st.title}>Аналитика</h1>

        <div className={st.flashlight2}></div>

        <div className={st.image_wrap}>
          <div className={st.image}></div>
        </div>
      </main>
    </>
  )
};
