import st from './SignIn.module.css';
import { ReactComponent as Logo } from '../../img/logo.svg';
import { Link, Outlet } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

export const SignIn = () => {

  return (
    <>
      <Helmet>
        <title>Вход | LIFTUP</title>
        <meta
          name="description"
          content="Войти в личный кабинет LIFTUP"
        />
      </Helmet>

      <main className={st.main_signin}>
        <Link className={st.background_img} to='/'>
          <Logo className={st.logo} />
        </Link>

        <div className={st.reg_autor}>
          <Outlet />
        </div>
      </main>
    </>
  )
};
