import st from './EquipmentEdit.module.css';
import { ReactComponent as BtnClose } from '../../../../../../img/btn_close.svg';
import { ReactComponent as BtnRequisites } from '../../../../../../img/icon_requisites.svg';
import { ReactComponent as BtnDetails } from '../../../../../../img/icon_details.svg';
import { ReactComponent as BtnAddress } from '../../../../../../img/icon_address.svg';
import { useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
// import { equipment_list } from '../../data/equipment_list_json.js';
import { Link } from 'react-router-dom';
import { ReactComponent as BtnLoad } from '../../../../../../img/btn_folder.svg';
import { ReactComponent as IconPDF } from '../../../../../../img/icon_pdf.svg';
import { ReactComponent as IconNoPDF } from '../../../../../../img/icon_no_pdf.svg';
import { fetchEquipmentNoDocAddEdit, fetchEquipmentWithDocAddEdit } from '../../fetches/fetchAddEdit';
import { useDispatch } from 'react-redux';
import { fetchEquipmentList } from 'src/store/equipment/getEquipmentList';
import { Part } from '../Part/Part';
import { handleInputChangeStop } from 'src/utils/handleInputChange';

export const EquipmentEdit = ({
  closeModal,
  status,
  efouid,
  uuid,
  equid,
  factorynumber,
  typeOfEquipment,
  manufacturername,
  cabintype,
  numberofstops,
  brandname,
  purpose,
  speed,
  loadcapacity,
  dateofacceptanceforwarranty,
  dateofcommissioning,
  passport,
  installationaddress,
  partsList,
  schedule,
  openEditEquip,
  openTechSpec,
  openAddressInstall,
  openDetails,
  openPayment,
}) => {
  // Первая деталь = Электродвигатель главного привода
  // Вторая деталь = Частотный преобразователь
  // Третья деталь = Главная плата управления лифтом
  // Четвертая деталь = Плата управления приводом дверей кабины

  // функция упорядочивет массив "partsList" по очередности деталей в новый массив "partsListMod"
  // с учетом пропуска деталей и некоторых дат для рендеринга
  const arrPartsListForRender = () => {
    const partsListMod = [
      {
        'partid': '',
        'partname': 'Электродвигатель главного привода',
        'selfnumber': '',
        'equipmentDate': {
          'dateofcommissioning': '',
          'dateofacceptanceforwarranty': '',
          'warrantyexpirationdate': ''
        }
      },
      {
        'partid': '',
        'partname': 'Частотный преобразователь',
        'selfnumber': '',
        'equipmentDate': {
          'dateofcommissioning': '',
          'dateofacceptanceforwarranty': '',
          'warrantyexpirationdate': ''
        }
      },
      {
        'partid': '',
        'partname': 'Главная плата управления',
        'selfnumber': '',
        'equipmentDate': {
          'dateofcommissioning': '',
          'dateofacceptanceforwarranty': '',
          'warrantyexpirationdate': ''
        }
      },
      {
        'partid': '',
        'partname': 'Плата управления приводом дверей кабины',
        'selfnumber': '',
        'equipmentDate': {
          'dateofcommissioning': '',
          'dateofacceptanceforwarranty': '',
          'warrantyexpirationdate': ''
        }
      }
    ];

    partsList.forEach(item => {

      if (item.partname === 'Электродвигатель главного привода') {
        partsListMod[0].partid = item.partid
        partsListMod[0].selfnumber = item.selfnumber
        partsListMod[0].equipmentDate.dateofcommissioning = item.equipmentDate.dateofcommissioning || ''
        partsListMod[0].equipmentDate.dateofacceptanceforwarranty = item.equipmentDate.dateofacceptanceforwarranty || ''
        partsListMod[0].equipmentDate.warrantyexpirationdate = item.equipmentDate.warrantyexpirationdate || ''
      }

      if (item.partname === 'Частотный преобразователь') {
        partsListMod[1].partid = item.partid
        partsListMod[1].selfnumber = item.selfnumber
        partsListMod[1].equipmentDate.dateofcommissioning = item.equipmentDate.dateofcommissioning || ''
        partsListMod[1].equipmentDate.dateofacceptanceforwarranty = item.equipmentDate.dateofacceptanceforwarranty || ''
        partsListMod[1].equipmentDate.warrantyexpirationdate = item.equipmentDate.warrantyexpirationdate || ''
      }

      if (item.partname === 'Главная плата управления') {
        partsListMod[2].partid = item.partid
        partsListMod[2].selfnumber = item.selfnumber
        partsListMod[2].equipmentDate.dateofcommissioning = item.equipmentDate.dateofcommissioning || ''
        partsListMod[2].equipmentDate.dateofacceptanceforwarranty = item.equipmentDate.dateofacceptanceforwarranty || ''
        partsListMod[2].equipmentDate.warrantyexpirationdate = item.equipmentDate.warrantyexpirationdate || ''
      }

      if (item.partname === 'Плата управления приводом дверей кабины') {
        partsListMod[3].partid = item.partid
        partsListMod[3].selfnumber = item.selfnumber
        partsListMod[3].equipmentDate.dateofcommissioning = item.equipmentDate.dateofcommissioning || ''
        partsListMod[3].equipmentDate.dateofacceptanceforwarranty = item.equipmentDate.dateofacceptanceforwarranty || ''
        partsListMod[3].equipmentDate.warrantyexpirationdate = item.equipmentDate.warrantyexpirationdate || ''
      }
    });

    return partsListMod;
  };

  const passportSelect = useRef(null);

  // редакирование оборудования
  const [editFactorynum, setEditFactorynum] = useState('');
  const [editStatusWarranty, setEditStatusWarranty] = useState('');
  const [editTypeOfEquipment, setEditTypeOfEquipment] = useState('');
  const [editManufacturername, setEditManufacturername] = useState('');
  const [editBrandname, setEditBrandname] = useState('');

  // технические характеристики
  const [editPurpose, setEditPurpose] = useState('');
  const [editSpeed, setEditSpeed] = useState('');
  const [editLoadcapacity, setEditLoadcapacity] = useState('');
  const [editProductdate, setEditProductdate] = useState('');
  const [editLaunchdate, setEditLaunchdate] = useState('');
  const [editPassport, setEditPassport] = useState('');
  const [cabinType, setCabinType] = useState('');
  const [numberStops, setNumberStops] = useState('');

  // адрес установки
  const [editAddressRegion, setEditAddressRegion] = useState('');
  const [editAddressCity, setEditAddressCity] = useState('');
  const [editAddressStreet, setEditAddressStreet] = useState('');
  const [editAddressHousenumber, setEditAddressHousenumber] = useState('');
  const [editAddressEntrancenumber, setEditAddressEntrancenumber] = useState('');

  // детали
  // 1
  const [editDet1det_id, setEditDet1det_id] = useState('');
  const [editDet1detProductdate, setEditDet1detProductdate] = useState('');
  const [editDet1detAcceptwarrantydate, setEditDet1detAcceptwarrantydate] = useState('');
  const [editDet1detExpiratwarrantydate, setEditDet1detExpiratwarrantydate] = useState('');
  // 2
  const [editDet2det_id, setEditDet2det_id] = useState('');
  const [editDet2detProductdate, setEditDet2detProductdate] = useState('');
  const [editDet2detAcceptwarrantydate, setEditDet2detAcceptwarrantydate] = useState('');
  const [editDet2detExpiratwarrantydate, setEditDet2detExpiratwarrantydate] = useState('');
  // 3
  const [editDet3det_id, setEditDet3det_id] = useState('');
  const [editDet3detProductdate, setEditDet3detProductdate] = useState('');
  const [editDet3detAcceptwarrantydate, setEditDet3detAcceptwarrantydate] = useState('');
  const [editDet3detExpiratwarrantydate, setEditDet3detExpiratwarrantydate] = useState('');
  // 4
  const [editDet4det_id, setEditDet4det_id] = useState('');
  const [editDet4detProductdate, setEditDet4detProductdate] = useState('');
  const [editDet4detAcceptwarrantydate, setEditDet4detAcceptwarrantydate] = useState('');
  const [editDet4detExpiratwarrantydate, setEditDet4detExpiratwarrantydate] = useState('');

  // технические характеристики показ инпута
  const [inputAppointmentShow, setInputAppointmentShow] = useState(false);
  const [inputSpeedShow, setInputSpeedShow] = useState(false);
  const [inputLoadcapacityShow, setInputLoadcapacityShow] = useState(false);
  const [inputProductdateShow, setInputProductdateShow] = useState(false);
  const [inputLaunchdateShow, setInputLaunchdateShow] = useState(false);
  const [inputPassportShow, setInputPassportShow] = useState(false);
  const [inputCabinTypetShow, setInputCabinTypetShow] = useState(false);
  const [inputNumberStopsShow, setInputNumberStopsShow] = useState(false);

  // адрес установки показ инпута
  const [inputAddressRegionShow, setInputAddressRegionShow] = useState(false);
  const [inputAddressCityShow, setInputAddressCityShow] = useState(false);
  const [inputAddressStreetShow, setInputAddressStreetShow] = useState(false);
  const [inputAddressHousenumberShow, setInputAddressHousenumberShow] = useState(false);
  const [inputAddressEntrancenumberShow, setInputAddressEntrancenumberShow] = useState(false);

  // содержание модального окна
  const [openEditEquipWin, setOpenEditEquipWin] = useState(false);
  const [openTechSpecWin, setOpenTechSpecWin] = useState(false);
  const [openAddressInstallWin, setOpenAddressInstallWin] = useState(false);
  const [openDetailsWin, setOpenDetailsWin] = useState(false);
  const [openPaymentScheduleWin, setOpenPaymentScheduleWin] = useState(false);

  const dispatch = useDispatch();

  // паттерн для ввода числа остановок
  const patternNumberStops = /^(?:[2-9]|[1-7][0-9]|80|)$/;

  // массив платежей
  const [arrPayments, setArrPayments] = useState([]);

  // картинка превью загружаемого паспорта оборудования
  const [passportPreview, setPassportPreview] = useState(null);
  // console.log('passportPreview: ', passportPreview);

  // данные выбранного для отправки файла
  const [selectedFileDouble, setSelectedFileDouble] = useState('');


  // данные для основного запроса
  const equipmentDataBody = {
    'efouid': efouid, // uuid оборудования???
    'efointid': 0, // оборудование организации int id for HZ (неизвесно)
    'uuid': uuid, // uuid организации
    'equid': equid, // uuid оборудования???

    // дата производства !!!ОБЗАТЕЛЬНЫЙ ФОРМАТ!!!(формат: месяц-число-год полностью)
    'dateofcommissioning': editLaunchdate,
    // дата принятия на гарантию !!!ОБЗАТЕЛЬНЫЙ ФОРМАТ!!!(формат: месяц-число-год полностью)
    'dateofacceptanceforwarranty': editProductdate,
    // дата истечения гарантии !!!ОБЗАТЕЛЬНЫЙ ФОРМАТ!!!(формат: месяц-число-год полностью)
    'warrantyexpirationdate': '4446-01-01',

    'installationaddress': { // адрес установки оборудования
      'region': editAddressRegion, // регион
      'city': editAddressCity, // город
      'street': editAddressStreet, // улица
      'house': editAddressHousenumber, // номер дома
      'entrance': editAddressEntrancenumber // подъезд
    },

    // новый новый объект детали
    'partsList': [
      {
        'partid': partsList[0]?.partid, // отправляется только при редактировании
        'partname': 'Электродвигатель главного привода',
        'selfnumber': editDet1det_id,
        'equipmentDate':
        {
          'dateofcommissioning': editDet1detProductdate,
          'dateofacceptanceforwarranty': editDet1detAcceptwarrantydate,
          'warrantyexpirationdate': editDet1detExpiratwarrantydate
        }
      },
      {
        'partid': partsList[1]?.partid, // отправляется только при редактировании
        'partname': 'Частотный преобразователь',
        'selfnumber': editDet2det_id,
        'equipmentDate':
        {
          'dateofcommissioning': editDet2detProductdate,
          'dateofacceptanceforwarranty': editDet2detAcceptwarrantydate,
          'warrantyexpirationdate': editDet2detExpiratwarrantydate
        }
      },
      {
        'partid': partsList[2]?.partid, // отправляется только при редактировании
        'partname': 'Главная плата управления лифтом',
        'selfnumber': editDet3det_id,
        'equipmentDate':
        {
          'dateofcommissioning': editDet3detProductdate,
          'dateofacceptanceforwarranty': editDet3detAcceptwarrantydate,
          'warrantyexpirationdate': editDet3detExpiratwarrantydate
        }
      },
      {
        'partid': partsList[3]?.partid, // отправляется только при редактировании
        'partname': 'Плата управления приводом дверей кабины',
        'selfnumber': editDet4det_id,
        'equipmentDate':
        {
          'dateofcommissioning': editDet4detProductdate,
          'dateofacceptanceforwarranty': editDet4detAcceptwarrantydate,
          'warrantyexpirationdate': editDet4detExpiratwarrantydate
        }
      }
    ],
    //'status' на гарантии или нет !!!ОБЗАТЕЛЬНОЕ!!!(формат: "UnderWarranty" "Entered")
    'status': editStatusWarranty,
    'factorynumber': editFactorynum, // заводской номер
    //'typeOfEquipment' тип оборудования !!!ОБЗАТЕЛЬНОЕ!!!(формат: "Elevator" "Escalator" "Travolator")
    'typeOfEquipment': editTypeOfEquipment,
    'manufacturer': { // производитель
      'id': '',
      'manufacturername': editManufacturername
    },
    "cabintype": cabinType,
    "numberofstops": numberStops,
    'brand': { // марка
      'id': '',
      'brandname': editBrandname,
      'manufacturerid': ''
    },
    'model': { // (хер знает)
      'id': '',
      'modelname': '',
      'brandid': ''
    },
    // 'purpose' ОБЗАТЕЛЬНОЕ(формат: "Passenger" "Cargo" "CargoPassenger")
    'purpose': editPurpose,
    'speed': editSpeed, // скорость
    'loadcapacity': +editLoadcapacity, // грузоподъемность
    // 'passportlinkuid': 'string', // добавляется в fetch после первого запроса
    // 'passportlink': '' // возвращается после второго запроса как ссылка на загруженный pdf документ
  };
  // поля помеченные ОБЗАТЕЛЬНЫЙ ФОРМАТ можно отправить только с заданным форматом
  // поля не помеченные ОБЗАТЕЛЬНОЕ можно отправить с нулем или пустой строкой

  // отправляет форму
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('uploadedFile', selectedFileDouble);
    // console.log(formData.uploadedFile);

    // первое это отправка с pdf второе - отправка без pdf
    if (selectedFileDouble !== '') {
      await fetchEquipmentWithDocAddEdit(formData, equipmentDataBody);
    } else {
      await fetchEquipmentNoDocAddEdit(equipmentDataBody);
    }

    dispatch(fetchEquipmentList(uuid));

    closeModal();

    console.log('форма отправлена');
  };

  // выбирает новый пасспорт на ПК и вставляет в превью
  const previewPassport = (e) => {
    const reader = new FileReader();

    const selectedFile = e.target.files[0];

    setSelectedFileDouble(selectedFile);

    if (selectedFile) {
      reader.readAsDataURL(selectedFile);
    }

    reader.onload = (readerEvent) => {
      if (selectedFile.type.includes('application')) {
        setPassportPreview(selectedFile.name);
      }
    };
  };

  // удаляем поле "превью пасспорта"
  const clearPreviewPassport = (e) => {
    e.preventDefault();
    setPassportPreview(null);
    setSelectedFileDouble('');
  };

  useEffect(() => {
    // редакирование оборудования
    setEditFactorynum(factorynumber);
    setEditStatusWarranty(status);
    setEditTypeOfEquipment(typeOfEquipment);
    setEditManufacturername(manufacturername);
    setCabinType(cabintype);
    setNumberStops(numberofstops);
    setEditBrandname(brandname);

    // массив платежей
    setArrPayments(schedule);

    // технические характеристики
    setEditPurpose(purpose);
    setEditSpeed(speed);
    setEditLoadcapacity(loadcapacity);
    setEditProductdate(dateofacceptanceforwarranty);
    setEditLaunchdate(dateofcommissioning);
    setEditPassport(passport.substring(passport.lastIndexOf('/') + 1));

    // адрес установки
    setEditAddressRegion(installationaddress.region);
    setEditAddressCity(installationaddress.city);
    setEditAddressStreet(installationaddress.street);
    setEditAddressHousenumber(installationaddress.house);
    setEditAddressEntrancenumber(installationaddress.entrance);

    // детали
    partsList.forEach(item => {
      if (item.partname === 'Электродвигатель главного привода') {
        setEditDet1det_id(item.selfnumber);
        setEditDet1detProductdate(item.equipmentDate.dateofcommissioning?.slice(0, -9) || '');
        setEditDet1detAcceptwarrantydate(item.equipmentDate.dateofacceptanceforwarranty?.slice(0, -9) || '');
        setEditDet1detExpiratwarrantydate(item.equipmentDate.warrantyexpirationdate?.slice(0, -9) || '');
      }
      if (item.partname === 'Частотный преобразователь') {
        setEditDet2det_id(item.selfnumber);
        setEditDet2detProductdate(item.equipmentDate.dateofcommissioning?.slice(0, -9) || '');
        setEditDet2detAcceptwarrantydate(item.equipmentDate.dateofacceptanceforwarranty?.slice(0, -9) || '');
        setEditDet2detExpiratwarrantydate(item.equipmentDate.warrantyexpirationdate?.slice(0, -9) || '');
      }
      if (item.partname === 'Главная плата управления лифтом') {
        setEditDet3det_id(item.selfnumber);
        setEditDet3detProductdate(item.equipmentDate.dateofcommissioning?.slice(0, -9) || '');
        setEditDet3detAcceptwarrantydate(item.equipmentDate.dateofacceptanceforwarranty?.slice(0, -9) || '');
        setEditDet3detExpiratwarrantydate(item.equipmentDate.warrantyexpirationdate?.slice(0, -9) || '');
      }
      if (item.partname === 'Плата управления приводом дверей кабины') {
        setEditDet4det_id(item.selfnumber);
        setEditDet4detProductdate(item.equipmentDate.dateofcommissioning?.slice(0, -9) || '');
        setEditDet4detAcceptwarrantydate(item.equipmentDate.dateofacceptanceforwarranty?.slice(0, -9) || '');
        setEditDet4detExpiratwarrantydate(item.equipmentDate.warrantyexpirationdate?.slice(0, -9) || '');
      }
    })

    setOpenEditEquipWin(openEditEquip);

    setOpenTechSpecWin(openTechSpec);

    setOpenAddressInstallWin(openAddressInstall);

    setOpenDetailsWin(openDetails);

    setOpenPaymentScheduleWin(openPayment);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    openEditEquip,
    openTechSpec,
    openDetails,
    openPayment,
    openAddressInstall,
    arrPayments,
  ]);

  // это список всех платежных документов и актов включая главный счет
  const [listPaymentSchedule, setListPaymentSchedule] = useState([]);

  // формируем список "listPaymentSchedule" в зависимости от того пустой или нет "paydoc" в "schedule"
  useEffect(() => {
    const arr = [];

    schedule.forEach(item => {
      // убрано 20.09.2024 в связи с новыми указаниями
      // if (Object.keys(item.paydoc).length !== 0) {
      //   arr.push(
      //     {
      //       dateshedpay: item?.dateshedpay?.slice(0, -9).split('-').reverse().join('.'),
      //       sumshedpay: item?.sumshedpay,
      //       status: item?.status,
      //       sheddocimagelink: item?.sheddocimagelink
      //     },
      //     {
      //       dateshedpay: item?.paydoc.datedoc?.slice(0, -12).split('-').reverse().join('.'),
      //       sumshedpay: item?.paydoc.sumdoc,
      //       status: item?.paydoc.status,
      //       sheddocimagelink: item?.paydoc.imagelink
      //     },
      //     ...item.alldocs?.map(elem => (
      //       {
      //         dateshedpay: elem?.datedoc?.slice(0, -12).split('-').reverse().join('.'),
      //         sumshedpay: elem?.sumdoc,
      //         status: elem?.status,
      //         sheddocimagelink: elem?.imagelink
      //       }
      //     ))
      //   )
      // } else {
      arr.push(
        {
          dateshedpay: item?.dateshedpay?.slice(0, -9).split('-').reverse().join('.'),
          sumshedpay: item?.sumshedpay,
          status: item?.status,
          sheddocimagelink: item?.sheddocimagelink
        },
        ...item.alldocs?.map(elem => (
          {
            dateshedpay: elem?.datedoc?.slice(0, -12).split('-').reverse().join('.'),
            sumshedpay: elem?.sumdoc,
            status: elem?.status,
            sheddocimagelink: elem?.imagelink
          }
        ))
      )
      // }
    })

    setListPaymentSchedule(arr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  // ===== пагинация на окошко платежей =====
  // номер текущей страницы
  const [currentPageNumber, setCurrentPageNumber] = useState(1);

  // количество платежей на странице
  const statementsQuantity = 3;

  // индекс последней заявки
  const lastStatementIndex = currentPageNumber * statementsQuantity;

  // индекс первой заявки
  const firstStatementIndex = lastStatementIndex - statementsQuantity;

  // список элементов текущей страницы
  const statementsCurrentList = listPaymentSchedule.slice(firstStatementIndex, lastStatementIndex);

  // общее количество платежей
  const totalStatementsQuantity = listPaymentSchedule.length;

  // список номеров-кнопок страниц
  let pages = [];
  for (let i = 1; i <= Math.ceil(totalStatementsQuantity / statementsQuantity); i++) {
    pages.push(i);
  }

  // функция кнопки следующая страница
  const nextPage = (e) => {
    e.preventDefault();
    setCurrentPageNumber(currentPageNumber !== pages.length ? prev => prev + 1 : prev => prev);
  };

  // функция кнопки предыдущая страница
  const prevPage = (e) => {
    e.preventDefault();
    setCurrentPageNumber(currentPageNumber > 1 ? prev => prev - 1 : prev => prev);
  };
  // ===== пагинация на окошко платежей=====

  return (
    <div className={st.clientsedit_wrap}>
      {/* просто закрывает модалку */}
      <button className={openEditEquipWin ? st.btn_close : st.hidden} onClick={closeModal}>
        <BtnClose />
      </button>

      {/* возвращает на предыдущее окно с кнопкой "сохранить" */}
      <button className={!openEditEquipWin ? st.btn_close_back : st.hidden} onClick={() => {
        setOpenEditEquipWin(true);
        setOpenTechSpecWin(false);
        setOpenAddressInstallWin(false);
        setOpenDetailsWin(false);
        // setBtnCloseBack(false);
      }}>
        <BtnClose />
      </button>

      {/* просто закрывает модалку при закрытом окне редактирвания оборудования и открытом окне платежей */}
      <button className={!openEditEquipWin && openPayment ? st.btn_close : st.hidden} onClick={closeModal}>
        <BtnClose />
      </button>

      <div className={st.clientsedit}>
        <form className={st.form}>
          {/* окно редактирования оборудования */}
          <fieldset className={openEditEquipWin ? st.ft_edit_client : st.hidden}>
            <h3 className={st.title}>Редактирование оборудования</h3>

            <div className={st.wrap}>
              {/* инпут редактирования заводского номера */}
              <div className={st.wrap_title}>
                <p className={editFactorynum !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>
                  Заводской номер</p>

                <div className={st.input_wrap_2}>
                  <label className={st.label_title}>
                    <input
                      className={st.input_title}
                      type='text'
                      name='editFactorynum'
                      value={editFactorynum}
                      placeholder='Заводской номер'
                      maxLength='22'
                      onChange={(e) => setEditFactorynum(e.target.value)}
                    />
                  </label>
                </div>
              </div>

              {/* инпут выбора статуса оборудования */}
              <div className={st.wrap_title}>
                <p className={editStatusWarranty !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>Статус</p>

                <div className={st.input_wrap_2}>
                  <label className={st.label_title}>
                    <select
                      className={st.input_select}
                      name='editStatusWarranty'
                      value={editStatusWarranty}
                      onChange={(e) => setEditStatusWarranty(e.target.value)}>
                      <option value=''>Статус</option>
                      <option value='Entered'>Заведен</option>
                      <option value='UnderWarranty'>Стандартна гарантия</option>
                      <option value='Extended'>Расширенная гарантия</option>
                    </select>
                  </label>
                </div>
              </div>
            </div>

            {/* инпут редактирования оборудования */}
            <div className={st.wrap_title}>
              <p className={editTypeOfEquipment !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>Оборудование</p>

              <div className={st.input_wrap}>
                <label className={st.label_title}>
                  <select
                    className={st.input_select}
                    name='editTypeOfEquipment'
                    value={editTypeOfEquipment}
                    onChange={(e) => setEditTypeOfEquipment(e.target.value)}>
                    <option value=''>Оборудование</option>
                    <option value='Elevator'>Лифт</option>
                    <option value='Travolator'>Травалатор</option>
                    <option value='Escalator'>Эскалатор</option>
                  </select>
                </label>
              </div>
            </div>

            {/* инпут выбора производителя */}
            <div className={st.wrap_title}>
              <p className={editManufacturername !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>Производитель</p>

              <div className={st.input_wrap}>
                <label className={st.label_title}>
                  <select
                    className={st.input_select}
                    name='editManufacturername'
                    value={editManufacturername}
                    onChange={(e) => setEditManufacturername(e.target.value)}>
                    <option value=''>Производитель</option>
                    <option value='BLT'>BLT</option>
                    <option value='KONE'>KONE</option>
                    <option value='OTIS'>OTIS</option>
                  </select>
                </label>
              </div>
            </div>

            {/* инпут ввода марки оборудования */}
            <div className={st.wrap_title}>
              <p className={editBrandname !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>
                Марка</p>

              <div className={st.input_wrap}>
                <label className={st.label_title}>
                  <input
                    className={st.input_title}
                    type='text'
                    name='editBrandname'
                    value={editBrandname}
                    placeholder='Марка'
                    onChange={(e) => setEditBrandname(e.target.value)}
                  />
                </label>
              </div>
            </div>

            {/* блок с кнопками */}
            <div className={st.btns_tech_spec}>
              <button
                className={st.button_tech_spec}
                onClick={(e) => {
                  e.preventDefault();
                  setOpenEditEquipWin(false);
                  setOpenTechSpecWin(true);
                  // setBtnCloseBack(true);
                }}>
                <BtnRequisites />
                Технические характеристики
              </button>

              <button
                className={st.button_tech_spec}
                onClick={(e) => {
                  e.preventDefault();
                  setOpenEditEquipWin(false);
                  setOpenDetailsWin(true);
                  // setBtnCloseBack(true);
                }}>
                <BtnDetails />
                Детали</button>

              <button
                className={st.button_tech_spec}
                onClick={(e) => {
                  e.preventDefault();
                  setOpenEditEquipWin(false);
                  setOpenAddressInstallWin(true);
                  // setBtnCloseBack(true);
                }}>
                <BtnAddress />
                Адрес&nbsp;установки</button>
            </div>

            <button
              className={st.btn_submit}
              onClick={handleFormSubmit}
            >Сохранить</button>
          </fieldset>

          {/* окно техн характеристики */}
          <fieldset className={openTechSpecWin ? st.ft_edit_requisites : st.hidden}>
            <h3 className={st.edit_title}>Технические характеристики</h3>

            {/* инпут редактирования назначения оборудования */}
            <div className={st.edit_input}>
              <div className={st.edit_field_wrap}>
                <p className={st.edit_field_title}>Назначение</p>

                <p className={!inputAppointmentShow ? st.edit_field_text : st.edit_field_text_hidden}>
                  {editPurpose === 'Passenger' ?
                    'Пассажирский' :
                    editPurpose === 'Cargo' ?
                      'Грузовой' :
                      editPurpose === 'CargoPassenger' ?
                        'Грузопассажирский' : 'Выберите назначение'}
                </p>

                <div className={st.wrap_p}>
                  <p
                    className={!inputAppointmentShow ? st.edit_field_btn : st.edit_field_btn_hide}
                    onClick={() => setInputAppointmentShow(!inputAppointmentShow)}>Изменить</p>

                  <p
                    className={inputAppointmentShow ? st.save_field_btn : st.save_field_btn_hide}
                    onClick={() => setInputAppointmentShow(false)}>Сохранить</p>
                </div>
              </div>

              <select
                className={inputAppointmentShow ? st.input_select2 : st.input_select2_hidden}
                name='editPurpose'
                value={editPurpose}
                onChange={(e) => setEditPurpose(e.target.value)}>
                <option value=''>Назначение</option>
                <option value='Passenger'>Пассажирский</option>
                <option value='Cargo'>Грузовой</option>
                <option value='CargoPassenger'>Грузопассажирский</option>
              </select>
            </div>

            {/* инпут редактирования скорости оборудования */}
            <div className={st.edit_input}>
              <div className={st.edit_field_wrap}>
                <p className={st.edit_field_title}>Скорость</p>

                <p
                  className={!inputSpeedShow ? st.edit_field_text : st.edit_field_text_hidden}>
                  {
                    editSpeed === 'SLOWEST' ?
                      '1,0 м/с' :
                      editSpeed === 'MEDIUMSLOW' ?
                        '1,6 м/с' :
                        editSpeed === 'SLOW' ?
                          '1,75 м/с' :
                          editSpeed === 'MEDIUM' ?
                            '2,0 м/с' :
                            editSpeed === 'FAST' ?
                              '2,5 м/с' :
                              editSpeed === 'SUPERFAST' ?
                                'Свыше 2,5 м/с' : 'Выберите скорость'
                  }
                </p>

                <div className={st.wrap_p}>
                  <p
                    className={!inputSpeedShow ? st.edit_field_btn : st.edit_field_btn_hide}
                    onClick={() => setInputSpeedShow(!inputSpeedShow)}>Изменить</p>

                  <p
                    className={inputSpeedShow ? st.save_field_btn : st.save_field_btn_hide}
                    onClick={() => setInputSpeedShow(false)}>Сохранить</p>
                </div>
              </div>

              <select
                className={inputSpeedShow ? st.input_select2 : st.input_select2_hidden}
                name='editSpeed'
                value={editSpeed}
                onChange={(e) => setEditSpeed(e.target.value)}>
                <option value=''>Скорость</option>
                <option value='SLOWEST'>1,0 м/с</option>
                <option value='MEDIUMSLOW'>1,6 м/с</option>
                <option value='SLOW'>1,75 м/с</option>
                <option value='MEDIUM'>2,0 м/с</option>
                <option value='FAST'>2,5 м/с</option>
                <option value='SUPERFAST'>Свыше 2,5 м/с</option>
              </select>
            </div>

            {/* инпут ввода типа кабины */}
            <div className={st.edit_input}>
              <div className={st.edit_field_wrap}>
                <p className={st.edit_field_title}>Тип кабины</p>

                <p
                  className={!inputCabinTypetShow ? st.edit_field_text : st.edit_field_text_hidden}>
                  {
                    cabinType === 'walkthrough' ?
                      'Проходная' :
                      cabinType === 'nonwalkthrough' ?
                        'Непроходная' : 'Выберите тип'
                  }
                </p>

                <div className={st.wrap_p}>
                  <p
                    className={!inputCabinTypetShow ? st.edit_field_btn : st.edit_field_btn_hide}
                    onClick={() => setInputCabinTypetShow(!inputCabinTypetShow)}>Изменить</p>

                  <p
                    className={inputCabinTypetShow ? st.save_field_btn : st.save_field_btn_hide}
                    onClick={() => setInputCabinTypetShow(false)}>Сохранить</p>
                </div>
              </div>

              <select
                className={inputCabinTypetShow ? st.input_select2 : st.input_select2_hidden}
                name='cabinType'
                value={cabinType}
                onChange={(e) => setCabinType(e.target.value)}>
                <option value=''>Тип кабины</option>
                <option value='walkthrough'>Проходная</option>
                <option value='nonwalkthrough'>Непроходная</option>
              </select>
            </div>

            {/* инпут редактирования грузоподъемности оборудования */}
            <div className={st.edit_input}>
              <div className={st.edit_field_wrap}>
                <p className={st.edit_field_title}>Грузоподъемность</p>

                <p
                  className={!inputLoadcapacityShow ? st.edit_field_text : st.edit_field_text_hidden}>{editLoadcapacity}</p>

                <div className={st.wrap_p}>
                  <p
                    className={!inputLoadcapacityShow ? st.edit_field_btn : st.edit_field_btn_hide}
                    onClick={() => setInputLoadcapacityShow(!inputLoadcapacityShow)}>Изменить</p>

                  <p
                    className={inputLoadcapacityShow ? st.save_field_btn : st.save_field_btn_hide}
                    onClick={() => setInputLoadcapacityShow(false)}>Сохранить</p>
                </div>
              </div>

              <select
                className={inputLoadcapacityShow ? st.input_select2 : st.input_select2_hidden}
                name='editLoadcapacity'
                value={editLoadcapacity}
                onChange={(e) => setEditLoadcapacity(e.target.value)}>
                <option value=''>Грузоподъёмность</option>
                <option value='400'>400 кг</option>
                <option value='600'>600 кг</option>
                <option value='1000'>1000 кг</option>
                <option value='1600'>1600 кг</option>
              </select>
            </div>

            {/* инпут редактирования количества остановок */}
            <div className={st.edit_input}>
              <div className={st.edit_field_wrap}>
                <p className={st.edit_field_title}>Количество остановок</p>

                <p
                  className={!inputNumberStopsShow ? st.edit_field_text : st.edit_field_text_hidden}>{numberStops}
                </p>

                <div className={st.wrap_p}>
                  <p
                    className={!inputNumberStopsShow ? st.edit_field_btn : st.edit_field_btn_hide}
                    onClick={() => setInputNumberStopsShow(!inputNumberStopsShow)}>Изменить</p>

                  <p
                    className={inputNumberStopsShow ? st.save_field_btn : st.save_field_btn_hide}
                    onClick={() => setInputNumberStopsShow(false)}>Сохранить</p>
                </div>
              </div>

              <input
                className={inputNumberStopsShow ? st.edit_entry : st.edit_entry_hidden}
                type='text'
                name='numberStops'
                value={numberStops}
                placeholder='Количество остановок'
                onChange={(e) => handleInputChangeStop(e, patternNumberStops, setNumberStops)}
              />
            </div>

            {/* инпут редактирования даты производства */}
            <div className={st.edit_input}>
              <div className={st.edit_field_wrap}>
                <p className={st.edit_field_title}>Дата производства</p>

                <p className={!inputProductdateShow ? st.edit_field_text : st.edit_field_text_hidden}>{
                  editProductdate !== '' ? editProductdate : 'Не назначено'
                }</p>

                <div className={st.wrap_p}>
                  <p
                    className={!inputProductdateShow ? st.edit_field_btn : st.edit_field_btn_hide}
                    onClick={() => setInputProductdateShow(!inputProductdateShow)}>Изменить</p>

                  <p
                    className={inputProductdateShow ? st.save_field_btn : st.save_field_btn_hide}
                    onClick={() => setInputProductdateShow(false)}>Сохранить</p>
                </div>
              </div>

              <div className={inputProductdateShow ? st.edit_entry : st.edit_entry_hidden}>
                <label className={st.label_title}>
                  <input
                    className={st.input_calendar}
                    type='date'
                    name='editProductdate'
                    value={editProductdate}
                    onChange={(e) => setEditProductdate(e.target.value)} />

                  <p className={editProductdate === '' ? st.placeholderedit : st.wrap_title_p_hide}>
                    Дата производства
                  </p>
                </label>
              </div>
            </div>

            {/* инпут редактирования даты запуска в эксплуатацию */}
            <div className={st.edit_input}>
              <div className={st.edit_field_wrap}>
                <p className={st.edit_field_title}>Дата запуска в эксплуатацию</p>

                <p className={!inputLaunchdateShow ? st.edit_field_text : st.edit_field_text_hidden}>{
                  editLaunchdate !== '' ? editLaunchdate : 'Не назначено'
                }</p>

                <div className={st.wrap_p}>
                  <p
                    className={!inputLaunchdateShow ? st.edit_field_btn : st.edit_field_btn_hide}
                    onClick={() => setInputLaunchdateShow(!inputLaunchdateShow)}>Изменить</p>

                  <p
                    className={inputLaunchdateShow ? st.save_field_btn : st.save_field_btn_hide}
                    onClick={() => setInputLaunchdateShow(false)}>Сохранить</p>
                </div>
              </div>

              <div className={inputLaunchdateShow ? st.edit_entry : st.edit_entry_hidden}>
                <label className={st.label_title}>
                  <input
                    className={st.input_calendar}
                    type='date'
                    name='editLaunchdate'
                    value={editLaunchdate}
                    onChange={(e) => setEditLaunchdate(e.target.value)} />

                  <p className={editLaunchdate === '' ? st.placeholderedit : st.wrap_title_p_hide}>
                    Дата запуска
                  </p>
                </label>
              </div>
            </div>

            {/* инпут редактирования пасспорта оборудования */}
            <div className={st.edit_input}>
              <div className={st.edit_field_wrap}>
                <p className={st.edit_field_title}>Паспорт</p>

                <p className={!inputPassportShow ? st.edit_field_text : st.edit_field_text_hidden}>{
                  passportPreview !== null ? passportPreview : editPassport !== '' ? editPassport : 'Не загружен'
                }</p>

                <div className={st.wrap_p}>
                  <p
                    className={!inputPassportShow ? st.edit_field_btn : st.edit_field_btn_hide}
                    onClick={() => setInputPassportShow(!inputPassportShow)}>Изменить</p>

                  {/* <p
                      className={inputPassportShow ? st.save_field_btn : st.save_field_btn_hide}
                      onClick={() => setInputPassportShow(false)}>Очистить</p> */}

                  <p
                    className={inputPassportShow ? st.save_field_btn : st.save_field_btn_hide}
                    onClick={() => setInputPassportShow(false)}>Сохранить</p>
                </div>
              </div>

              <div className={inputPassportShow ? st.edit_entry : st.edit_entry_hidden}>
                <label className={st.label_image}>

                  <input
                    ref={passportSelect}
                    className={st.input_image}
                    type='file'
                    name='passport'
                    accept='application/pdf'
                    onChange={previewPassport}
                  />

                  {passportPreview !== null
                    ? <div className={st.wrap_prev}>
                      <p><span>{passportPreview}</span></p>
                      <button onClick={clearPreviewPassport}>x</button>
                    </div>
                    : <p>Загрузить паспорт...</p>}

                  <BtnLoad />
                </label>
              </div>
            </div>

          </fieldset>

          {/* окно адрес установки */}
          <fieldset className={openAddressInstallWin ? st.ft_edit_requisites : st.hidden}>
            <h3 className={st.edit_title}>Адрес установки</h3>

            {/* инпут редактирования адрес-региона оборудования */}
            <div className={st.edit_input}>
              <div className={st.edit_field_wrap}>
                <p className={st.edit_field_title}>Регион</p>

                <p className={!inputAddressRegionShow ? st.edit_field_text : st.edit_field_text_hidden}>{editAddressRegion}</p>

                <div className={st.wrap_p}>
                  <p
                    className={!inputAddressRegionShow ? st.edit_field_btn : st.edit_field_btn_hide}
                    onClick={() => setInputAddressRegionShow(!inputAddressRegionShow)}>Изменить</p>

                  <p
                    className={inputAddressRegionShow ? st.save_field_btn : st.save_field_btn_hide}
                    onClick={() => setInputAddressRegionShow(false)}>Сохранить</p>
                </div>
              </div>

              <input
                className={inputAddressRegionShow ? st.edit_entry : st.edit_entry_hidden}
                type='text'
                name='editAddressRegion'
                value={editAddressRegion}
                placeholder='Регион'
                onChange={(e) => setEditAddressRegion(e.target.value)}
              />
            </div>

            {/* инпут редактирования адрес-города оборудования */}
            <div className={st.edit_input}>
              <div className={st.edit_field_wrap}>
                <p className={st.edit_field_title}>Город</p>

                <p className={!inputAddressCityShow ? st.edit_field_text : st.edit_field_text_hidden}>{editAddressCity}</p>

                <div className={st.wrap_p}>
                  <p
                    className={!inputAddressCityShow ? st.edit_field_btn : st.edit_field_btn_hide}
                    onClick={() => setInputAddressCityShow(!inputAddressCityShow)}>Изменить</p>

                  <p
                    className={inputAddressCityShow ? st.save_field_btn : st.save_field_btn_hide}
                    onClick={() => setInputAddressCityShow(false)}>Сохранить</p>
                </div>
              </div>

              <input
                className={inputAddressCityShow ? st.edit_entry : st.edit_entry_hidden}
                type='text'
                name='editAddressCity'
                value={editAddressCity}
                placeholder='Город'
                onChange={(e) => setEditAddressCity(e.target.value)}
              />
            </div>

            {/* инпут редактирования адрес-улицы оборудования */}
            <div className={st.edit_input}>
              <div className={st.edit_field_wrap}>
                <p className={st.edit_field_title}>Улица</p>

                <p className={!inputAddressStreetShow ? st.edit_field_text : st.edit_field_text_hidden}>{editAddressStreet}</p>

                <div className={st.wrap_p}>
                  <p
                    className={!inputAddressStreetShow ? st.edit_field_btn : st.edit_field_btn_hide}
                    onClick={() => setInputAddressStreetShow(!inputAddressStreetShow)}>Изменить</p>

                  <p
                    className={inputAddressStreetShow ? st.save_field_btn : st.save_field_btn_hide}
                    onClick={() => setInputAddressStreetShow(false)}>Сохранить</p>
                </div>
              </div>

              <input
                className={inputAddressStreetShow ? st.edit_entry : st.edit_entry_hidden}
                type='text'
                name='editAddressStreet'
                value={editAddressStreet}
                placeholder='Улица'
                onChange={(e) => setEditAddressStreet(e.target.value)}
              />
            </div>

            {/* инпут редактирования адрес-номера-дома оборудования */}
            <div className={st.edit_input}>
              <div className={st.edit_field_wrap}>
                <p className={st.edit_field_title}>Номер дома </p>

                <p className={!inputAddressHousenumberShow ? st.edit_field_text : st.edit_field_text_hidden}>{editAddressHousenumber}</p>

                <div className={st.wrap_p}>
                  <p
                    className={!inputAddressHousenumberShow ? st.edit_field_btn : st.edit_field_btn_hide}
                    onClick={() => setInputAddressHousenumberShow(!inputAddressHousenumberShow)}>Изменить</p>

                  <p
                    className={inputAddressHousenumberShow ? st.save_field_btn : st.save_field_btn_hide}
                    onClick={() => setInputAddressHousenumberShow(false)}>Сохранить</p>
                </div>
              </div>

              <input
                className={inputAddressHousenumberShow ? st.edit_entry : st.edit_entry_hidden}
                type='text'
                name='editAddressHousenumber'
                value={editAddressHousenumber}
                placeholder='Номер дома '
                onChange={(e) => setEditAddressHousenumber(e.target.value)}
              />
            </div>

            {/* инпут редактирования адрес-номера-подъезда оборудования */}
            <div className={st.edit_input}>
              <div className={st.edit_field_wrap}>
                <p className={st.edit_field_title}>Номер подъезда</p>

                <p className={!inputAddressEntrancenumberShow ? st.edit_field_text : st.edit_field_text_hidden}>{editAddressEntrancenumber}</p>

                <div className={st.wrap_p}>
                  <p
                    className={!inputAddressEntrancenumberShow ? st.edit_field_btn : st.edit_field_btn_hide}
                    onClick={() => setInputAddressEntrancenumberShow(!inputAddressEntrancenumberShow)}>Изменить</p>

                  <p
                    className={inputAddressEntrancenumberShow ? st.save_field_btn : st.save_field_btn_hide}
                    onClick={() => setInputAddressEntrancenumberShow(false)}>Сохранить</p>
                </div>
              </div>

              <input
                className={inputAddressEntrancenumberShow ? st.edit_entry : st.edit_entry_hidden}
                type='text'
                name='editAddressEntrancenumber'
                value={editAddressEntrancenumber}
                placeholder='Номер подъезда'
                onChange={(e) => setEditAddressEntrancenumber(e.target.value)}
              />
            </div>
          </fieldset>

          {/* окно детали */}
          <fieldset className={openDetailsWin ? st.ft_edit_requisites : st.hidden}>
            <h3 className={st.edit_title}>Детали</h3>

            {
              arrPartsListForRender().map(item =>
                item.partname === 'Электродвигатель главного привода' ?
                  <Part
                    key={uuidv4()}

                    partid={item.partid || ''}
                    partname={item.partname || ''}

                    editDetId={editDet1det_id || ''}
                    setEditDetId={setEditDet1det_id || ''}
                    editDetProductdate={editDet1detProductdate || ''}
                    setEditDetProductdate={setEditDet1detProductdate || ''}
                    editDetAcceptwarrantydate={editDet1detAcceptwarrantydate || ''}
                    setEditDetAcceptwarrantydate={setEditDet1detAcceptwarrantydate || ''}
                    editDetExpiratwarrantydate={editDet1detExpiratwarrantydate || ''}
                    setEditDetExpiratwarrantydate={setEditDet1detExpiratwarrantydate || ''}
                  /> :
                  item.partname === 'Частотный преобразователь' ?
                    <Part
                      key={uuidv4()}

                      partid={item.partid || ''}
                      partname={item.partname || ''}

                      editDetId={editDet2det_id || ''}
                      setEditDetId={setEditDet2det_id || ''}
                      editDetProductdate={editDet2detProductdate || ''}
                      setEditDetProductdate={setEditDet2detProductdate || ''}
                      editDetAcceptwarrantydate={editDet2detAcceptwarrantydate || ''}
                      setEditDetAcceptwarrantydate={setEditDet2detAcceptwarrantydate || ''}
                      editDetExpiratwarrantydate={editDet2detExpiratwarrantydate || ''}
                      setEditDetExpiratwarrantydate={setEditDet2detExpiratwarrantydate || ''}
                    /> :
                    item.partname === 'Главная плата управления' ?
                      <Part
                        key={uuidv4()}

                        partid={item.partid || ''}
                        partname={item.partname || ''}

                        editDetId={editDet3det_id || ''}
                        setEditDetId={setEditDet3det_id || ''}
                        editDetProductdate={editDet3detProductdate || ''}
                        setEditDetProductdate={setEditDet3detProductdate || ''}
                        editDetAcceptwarrantydate={editDet3detAcceptwarrantydate || ''}
                        setEditDetAcceptwarrantydate={setEditDet3detAcceptwarrantydate || ''}
                        editDetExpiratwarrantydate={editDet3detExpiratwarrantydate || ''}
                        setEditDetExpiratwarrantydate={setEditDet3detExpiratwarrantydate || ''}
                      /> :
                      editTypeOfEquipment === 'Elevator' ?
                        item.partname === 'Плата управления приводом дверей кабины' ?
                          <Part
                            key={uuidv4()}

                            partid={item.partid || ''}
                            partname={item.partname || ''}

                            editDetId={editDet4det_id || ''}
                            setEditDetId={setEditDet4det_id || ''}
                            editDetProductdate={editDet4detProductdate || ''}
                            setEditDetProductdate={setEditDet4detProductdate || ''}
                            editDetAcceptwarrantydate={editDet4detAcceptwarrantydate || ''}
                            setEditDetAcceptwarrantydate={setEditDet4detAcceptwarrantydate || ''}
                            editDetExpiratwarrantydate={editDet4detExpiratwarrantydate || ''}
                            setEditDetExpiratwarrantydate={setEditDet4detExpiratwarrantydate || ''}
                          /> : null : null
              )
            }
          </fieldset>

          {/* окно график платежей */}
          <fieldset className={openPaymentScheduleWin ? st.ft_edit_payments : st.hidden}>
            <h3 className={st.edit_title}>График оплат</h3>

            <ul className={st.titles_table_list}>
              <li className={st.titles_table_item}>Дата</li>
              <li className={st.titles_table_item}>Сумма</li>
              <li className={st.titles_table_item}>Статус</li>
              <li className={st.titles_table_item}>Счет</li>
            </ul>

            <ul className={st.payment_list}>
              <li className={st.payment_item}>
                {statementsCurrentList.map(item =>
                  <ul className={st.payment_item_list} key={uuidv4()}>
                    <li className={st.payment_list_elem}>{item.dateshedpay}</li>
                    <li className={st.payment_list_elem}>{`${item.sumshedpay}`} &#8381;</li>
                    <li className={st.payment_list_elem}>{
                      item.status === 'PAYED' ? 'Оплачен' :
                        item.status === 'SHEDULED' ? 'Запланирован' :
                          item.status === 'NOTPAYED' ? 'Не оплачен' :
                            item.status === 'OVERDUE' ? 'Просрочен' : 'Нет данных'
                    }</li>
                    <li className={st.payment_list_elem}>
                      {
                        item.sheddocimagelink ?
                          <Link className={st.item_icon}
                            to={item.sheddocimagelink} target='_blank'><IconPDF />
                          </Link> : <div className={st.item_icon}><IconNoPDF /></div>
                      }
                    </li>
                  </ul>
                )}
              </li>
            </ul>

            {/* пагинация */}
            <div className={st.pagination}>
              <button
                className={statementsQuantity <= totalStatementsQuantity ? st.pagination_btn_prev : st.hidden}
                onClick={prevPage}>
              </button>
              {
                pages.map((item) => {
                  return (
                    <button
                      key={uuidv4()}
                      onClick={(e) => {
                        e.preventDefault();
                        setCurrentPageNumber(item);
                      }}
                      className={
                        pages.length === 1 ? st.hidden :
                          item === currentPageNumber
                            ? st.pagination_item_active
                            : st.pagination_item
                      }>
                      {item}
                    </button>
                  );
                })
              }
              <button
                className={statementsQuantity <= totalStatementsQuantity ? st.pagination_btn_next : st.hidden}
                onClick={nextPage}>
              </button>
            </div>
          </fieldset>
        </form>
      </div>
    </div>
  )
};
