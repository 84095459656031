import st from './ServiceCenters.module.css';
import { useEffect, useState } from 'react';
import { ServiceCenterCard } from './components/ServiceCenterCard/ServiceCenterCard';
// import { servicecenters_list } from './data/servicecenters_list_json.js';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { fetchServicecentersList } from 'src/store/servicecenters/getServicecentersList';
import { Helmet } from 'react-helmet-async';


export const ServiceCenters = () => {
  // значение выбор по городу
  const [choosingCity, setChoosingCity] = useState('');

  // сортированный массив сервисных центров
  const [servCenterSort, setServCenterSort] = useState([]);

  const { serviceCentres } = useSelector(state => state.servicecentersListReducer.dataServicecentersList);
  const { status } = useSelector(state => state.servicecentersListReducer);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!serviceCentres && status !== 'failed') {
      dispatch(fetchServicecentersList());
    }

    if (status === 'success') {
      // сортируем  массив по ключу "slideOrder" (по порядковому номеру слайда)
      setServCenterSort(Array.from(serviceCentres).sort((a, b) => {
        return (a.orgcount > b.orgcount) - 0.5;
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, status]);

  return (
    <>
      <Helmet>
        <title>Сервисные центры | LIFTUP</title>
        <meta
          name="description"
          content="Наши сервисные центры уже открыты в таких городах, как Москва, Санкт-Петербург, Казань, Уфа, Омск, Екатеринбург, Тюмень, Новосибирск."
        />
      </Helmet>

      <main className={`${st.main} container`}>
        <h1 className={st.title}>Сервисные центры</h1>

        <div className={st.flashlight2}></div>

        <div className={st.service_container}>
          <h2 className={st.subtitle}>
            На сегодняшний день у нас уже открыты сервисные центры в таких городах, как Москва, Санкт-Петербург, Казань, Уфа, Омск, Екатеринбург, Тюмень, Новосибирск.
          </h2>

          <p className={st.description}>
            Выбирайте "Лифтап" и наслаждайтесь всеми преимуществами работы с надежным партнером, обеспечивающим безопасность, комфорт и экономию. Мы рады видеть вас среди наших клиентов и гарантируем качественный сервис и индивидуальный подход к каждому заказчику.
          </p>

          <div className={st.map}></div>

          <div className={st.wrap_select}>
            <p className={choosingCity !== '' ? st.hint_show : st.hint_hide}>
              Выбор города</p>

            <div className={st.select_wrap}>
              <label className={st.label}>
                <select
                  className={st.select}
                  name='choosingCity'
                  value={choosingCity}

                  onChange={(e) => setChoosingCity(e.target.value)}>
                  <option value=''>Выберите город</option>
                  <option value='Москва'>Москва</option>
                  <option value='Санкт-Петербург'>Санкт-Петербург</option>
                  <option value='Казань'>Казань</option>
                  <option value='Уфа'>Уфа</option>
                  <option value='Омск'>Омск</option>
                  <option value='Екатеринбург'>Екатеринбург</option>
                  <option value='Тюмень'>Тюмень</option>
                  <option value='Новосибирск'>Новосибирск</option>
                </select>
              </label>
            </div>
          </div>

          <ul className={st.servicecenter_card_list}>
            {servCenterSort.map(item => (
              choosingCity === item.city ?
                <ServiceCenterCard
                  key={uuidv4()}
                  name={item.name}
                  logo={item.logo}
                  city={item.city}
                  address={item.address}
                  jobschedule={item.jobschedule}
                  phone={item.phone}
                  email={item.email}
                  coordinates={item.coordinates}
                />
                : null
            ))}
          </ul>
        </div>
      </main>
    </>
  )
};
