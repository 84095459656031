import { Link } from 'react-router-dom';
import st from './Newscard.module.css';
import nophoto from '../../../../img/nophoto.svg';

export const Newscard = ({ newsid, imagelink, newsdate, newsname, newspreview }) => {
  return (
    <Link className={st.newscard} to={`/news/newsread/${newsid}`}>
      <div className={st.newscardborder}>
        <div className={st.newscardborder_back}></div>
      </div>

      <img
        className={st.news_img}
        src={imagelink && !imagelink?.endsWith('unknown') ? imagelink : nophoto}
        alt={newsname} />

      <div className={st.newswrap}>
        <p className={st.news_date}>{newsdate ? newsdate : '15-й век'}</p>

        <h3 className={st.news_title}>{newsname}</h3>

        <p className={st.news_announse}>{newspreview}</p>
      </div>

      <div className={st.line}></div>

      <div className={st.newscardfooter}>
        <p className={st.news_more}>Подробнее</p>

        <div className={st.news_btn}>
          <div className={st.news_btn_arrow}></div>
        </div>
      </div>
    </Link>
  )
};
