import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const ScrollToTop = () => {
  const { pathname } = useLocation();

  // старый вариант несовсем рабочий
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, [pathname]);

  // новый вариант рабочий
  useEffect(() => {
    // принудительно сбрасываем скролл через небольшую задержку
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }, [pathname]);

  return null;
};
