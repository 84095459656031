// YANDEX
// API KEY яндекс карт 'JavaScript API и HTTP Геокодер'
const API_KEY_YANDEXMAPS = 'e9163cd5-5651-4320-8818-070a818372c6';

// подключение яндекс-мапс скрипта с ключем геокодер
export const API_YANDEX_MAPS = `https://api-maps.yandex.ru/v3/?apikey=${API_KEY_YANDEXMAPS}&lang=ru_RU`;

// получение точек координат по адресу
export const GET_COORD = `https://geocode-maps.yandex.ru/1.x/?apikey=${API_KEY_YANDEXMAPS}&format=json`;
// YANDEX

// ===========================================================================

// ОСНОВНОЙ API сервера
export const API_URL_ROOT = 'https://api.liftup.pro';
export const API_URL = 'https://api.liftup.pro/lfapi';

// отправка адреса е-почты и телефона для обратной связи без авторизации
export const FEEDBACKCONTACTS_POST = `${API_URL}/v1/ext/email/feedback`;

// получение регионов по фиас
export const FIASREGIONS_GET = `${API_URL}/v1/address/region`;

// получение районов, городов, улиц и т.д. по фиас (доп параметры в теле запроса)
export const FIASGETADDRESSES_POST = `${API_URL}/v1/address/addr`;


// АВТОРИЗАЦИЯ ЛОГИН ПАРОЛЬ И ВОЗВРАТ КЛИЕНТА
// запрос с отправкой логина и пароля и получение токенов
// export const LOGIN_POST = `${API_URL}/v1/user/login`; старый вариант
export const LOGIN_POST = `${API_URL_ROOT}/auth/v1/auth`;

// запрос на получение данных юзера
export const LOGIN_GET = `${API_URL}/v1/user/alogin`; // старый вариант
export const LOGIN_GET2 = `${API_URL}/v1/user/alogin2`;

// запрос на получение SHS по номеру телефона
export const SHS_POST = `${API_URL}/v1/user/shc`;


// СМЕНА ПАРОЛЯ ИЗ ЛИЧНОГО КАБИНЕТА
// запрос на смену пароля
export const CHANGE_PASS = `${API_URL}/v1/user/setnewpass`;

// СМЕНА E-MAIL ИЗ ЛИЧНОГО КАБИНЕТА (окно редактирование данных -> изменение e-mail)
// запрос на смену e-mail
export const CHANGE_MAIL = `${API_URL}/v1/user/setnewmail`;


// ОТПРАВКА НОМЕРА ТЕЛЕФОНА
// POST при восстановлении забытого пароля юзера отправляем номер телефона, он проверяется на сервере 
// если есть в базе, получаем звонок на номер, последние 4 цифры - код для дальнейшего ввода и отправки
// если нет в базе, получаем получаем отрицательный ответ
export const PHONE_RECOVERY = `${API_URL}/v1/user/recovery`;


// ОТПРАВКА НОМЕРА ТЕЛЕФОНА ПРИ РЕГИСТРАЦИИ
// POST при регистрации нового юзера отправляем номер телефона (+ позже зашифрованный идентификатор)
// получаем звонок на номер, последние 4 цифры - код для дальнейшего ввода и отправки
export const PHONE_CHECKW = `${API_URL}/v1/user/checkw`;


// CMC
// отправка кода из смс
export const USER_VERIFY_SMS = `${API_URL}/v1/user/verifysms`;


// СМЕНА НОМЕРА ТЕЛЕФОНА
// POST при смене номера телефона (+ позже зашифрованный идентификатор)
export const PHONE_SETNEWPHONE = `${API_URL}/v1/user/setnewphone`;


// MEDIAFILE
// добавляем медиафайл, плучаем "mediafileuid" для отправки в след запросе
export const MEDIAFILE_ADD = `${API_URL}/v1/media/upload`;


// НОВОСТИ
// добавляем или редактируем новость
export const NEWS_ADD = `${API_URL}/v1/news/add`;
// удаляем новость
export const NEWS_DEL = `${API_URL}/v1/news/del/`;
// получить список имеющихся новостей
export const NEWS_LIST = `${API_URL}/v1/news`;


// СЛАЙДЫ
// добавить или редактировать слайд
export const SLIDES_ADD = `${API_URL}/v1/slide/add`;
// изменить порядок слайдов
export const SLIDES_REORDER = `${API_URL}/v1/slide/reorder`;
// удалить слайд
export const SLIDES_DEL = `${API_URL}/v1/slide/del/`;
// получить все имеющиеся слайды
export const SLIDES_LIST = `${API_URL}/v1/slide`;


// ДОКУМЕНТЫ АДМИНА ДЛЯ ОБЩЕГО КОНТЕНТА САЙТА
// добавить или редактировать документ
export const DOCS_ADD = `${API_URL}/v1/docs/add`;
// изменить порядок документов
export const DOCS_REORDER = `${API_URL}/v1/docs/reorder`;
// удалить документ
export const DOCS_DEL = `${API_URL}/v1/docs/del/`;
// получить все имеющиеся документы
export const DOCS_LIST = `${API_URL}/v1/docs`;


// НАШИ КЛИЕНТЫ
// добавить или редактировать нашего клиента
export const OURCLIENTS_ADD = `${API_URL}/v1/org/ourorg/add`;
// изменить порядок наших клиентов
export const OURCLIENTS_REORDER = `${API_URL}/v1/org/ourorg/reorder`;
// удалить нашего клиента
export const OURCLIENTS_DEL = `${API_URL}/v1/org/ourorg/del/`;
// получить список наших клиентов
export const OURCLIENTS_LIST = `${API_URL}/v1/org/ourorg`;


// СЕРВИСНЫЕ ЦЕНТРЫ
// добавить или редактировать сервисный центр
export const SERVICECENTERS_ADD = `${API_URL}/v1/scentre/add`;
// удалить сервисный центр
export const SERVICECENTERS_DEL = `${API_URL}/v1/scentre/del/`;
// получить список сервисных центров
export const SERVICECENTERS_LIST = `${API_URL}/v1/scentre`;


// ОРГАНИЗАЦИИ
// добавить организацию
export const ORG_ADD = `${API_URL}/v1/org/add`;
// редактировать организацию
export const ORG_UPD = `${API_URL}/v1/org/upd`;
// редактировать организацию расширенно
export const ORG_UPDV2 = `${API_URL}/v1/org/updv2`;
// получить упрощенный список организациЙ
export const ORG_LIST_NOTFULL = `${API_URL}/v1/org/get`;
// получить полный список организаций
export const ORG_LIST_FULL = `${API_URL}/v1/org/getfull`;
// удалить организацию
export const ORG_DEL = `${API_URL}/v1/org/del/`;


// ОБОРУДОВАНИЕ
// получить полный список оборудования
export const EQUIP_LIST_ALL = `${API_URL}/v1/eqoforg`;
// получить список оборудования по id организации
export const EQUIP_LIST_ORG = `${API_URL}/v1/eqoforg/o/`;
// добавить оборудование по id организации (id указывается в теле запроса)
export const EQUIP_ADD = `${API_URL}/v1/eqoforg/add`;
// удалить оборудование по id оборудования
export const EQUIP_DEL = `${API_URL}/v1/eqoforg/del/`;
// получить оборудование по его uuid не работает
export const EQUIP_BY_UUIDEQUIP = `${API_URL}/v1/eqoforg/`;


// ДОКУМЕНТЫ ПЛАТЕЖНЫЕ ПО ОБОРУДОВАНИЮ ОРГАНИЗАЦИИ
// получить список всех документов, всех организаций, всего оборудования (не исльзуется)
export const FIN_DOCS_ALL = `${API_URL}/v1/findoc/paydoc/all`;
// получить список актов, накладных, счетов организации (не исльзуется)
// по uuid организации (указывается после слэша)
export const FIN_DOCS_ORG_ALL = `${API_URL}/v1/findoc/alldoc/o/`;
// получить список всех платежных документов организации (не исльзуется)
// по uuid организации (указывается после слэша)
export const FIN_DOCS_ORG_PAY = `${API_URL}/v1/findoc/paydoc/o/`;
// получить документ по его uuid PayDoc (указывается после слэша) (не исльзуется)
export const FIN_DOC = `${API_URL}/v1/findoc/paydoc/`;
// получить все документы по uuid организации (указывается после слэша)
export const FIN_DOC_ALL_AND_PAY = `${API_URL}/v1/findoc/frontdoc/o/`;


// ДЕТАЛИ на сайте пока не используется
// добавить деталь
export const PART_ADD = `${API_URL}/v1/part/add`;
// возврат деталей или детали по uuid ее оборудования
export const PART_BY_ID_EQUIP = `${API_URL}/v1/part/`;
// возврат детали по ее uuid
export const PART_BY_ID_PART = `${API_URL}/v1/part/one/`;


// ЗАЯВКИ
// добавление заявки на гарантийное обслуживание
export const LIFTDOWN_ADD = `${API_URL}/v1/requestdoc/add`;
// удаление заявки на гарантийное обслуживание по ее id
export const LIFTDOWN_DEL = `${API_URL}/v1/requestdoc/del/`;
// получить список всех заявок организаций
export const LIFTDOWN_LIST_ALL = `${API_URL}/v1/requestdoc`;
// получить заявки организаций по uuid организации???
export const LIFTDOWN_LIST_ORG = `${API_URL}/v1/requestdoc/o/`;
// изменение статуса заявоки???
export const LIFTDOWN_STATUS_CHANGE = `${API_URL}/v1/requestdoc/status/set`;






// 1C SYSTEM
// вернуть статус обмена
export const STATUS_EXCH = `${API_URL}/v1/ext/onec`;
// загрузка номенклатуры
export const LOAD_MOMENCLATURE = `${API_URL}/v1/nomencl/load`;
// загрузка номенклатурных групп
export const LOAD_MOMENCLATURE_GROUP = `${API_URL}/v1/nomengrp/load`;
// вернуть организации
export const RETURN_ORG = `${API_URL}/v1/org/get/exp`;
