import st from './DeptEquipment.module.css';
import { useEffect, useState } from 'react';
import { ReactComponent as IconSetting } from '../../../../img/icon_setting.svg';
// import { equipment_list } from './data/equipment_list_json.js';
import { v4 as uuidv4 } from 'uuid';
import { Overlay } from 'src/components/Overlay/Overlay';
import { EquipmentAdd } from './components/EquipmentAdd/EquipmentAdd.jsx';
import { EquipmentPreview } from './components/EquipmentPreview/EquipmentPreview.jsx';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchEquipmentList, removeEquipmentList } from 'src/store/equipment/getEquipmentList';

export const DeptEquipment = () => {
  // откр закр модалка
  const [modalAdd, setModalAdd] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // название текущей организации, оборудование которой отображается в списке
  const [equipTitle, setEquipTitle] = useState('');

  // 'uuid' и 'name' выбранной организации для построения списка ее оборудования и вывода ее названия
  let { сurrentOrganization } = useSelector(state => state.currentOrganizationReducer);

  // список оборудования в store
  let { equipmentOfOrganizatioList } = useSelector(state => state.equipmentListReducer.dataEquipmentList);

  // статус  выполнения запроса на получение списка оборудования
  const { status } = useSelector(state => state.equipmentListReducer);

  // состояние списка для функций фильтрации
  const [equipmentOfOrganizatioListToFilter, setEquipmentOfOrganizatioListToFilter] = useState([]);

  // показывает, скрывает поля фильтрации
  const [filterListShow, setFilterListShow] = useState(false);
  // управление инпутами фильтрации
  const [filterForId, setFilterForId] = useState('');
  const [filterForStatus, setFilterForStatus] = useState('');
  const [filterForNumber, setFilterForNumber] = useState('');
  const [filterForEquip, setFilterForEquip] = useState('');
  const [filterForManufacture, setFilterForManufacture] = useState('');
  const [filterForBrand, setFilterForBrand] = useState('');
  const [filterForProductDate, setFilterForProductDate] = useState('');
  const [filterForLaunchDate, setFilterForLaunchDate] = useState('');

  // является фиксированным и неизменным массивом для перебора при поиске
  const [constEquipmentList, setConstEquipmentList] = useState([]);
  // 'letEquipmentList' является изменяемым массивом для перебора и отображения
  const [letEquipmentList, setLetEquipmentList] = useState(constEquipmentList);

  // ===== пагинация =====
  // номер текущей страницы
  const [currentPageNumber, setCurrentPageNumber] = useState(1);

  // количество заявок на странице
  const statementsQuantity = 4;

  // индекс последней заявки
  const lastStatementIndex = currentPageNumber * statementsQuantity;

  // индекс первой заявки
  const firstStatementIndex = lastStatementIndex - statementsQuantity;

  // список элементов текущей страницы
  const equipmentCurrentList = letEquipmentList.slice(firstStatementIndex, lastStatementIndex);

  // общее количество заявок
  const totalStatementsQuantity = letEquipmentList.length;

  // список номеров-кнопок страниц
  let pages = [];
  for (let i = 1; i <= Math.ceil(totalStatementsQuantity / statementsQuantity); i++) {
    pages.push(i);
  }

  // функция кнопки следующая страница
  const nextPage = () => {
    setCurrentPageNumber(currentPageNumber !== pages.length ? prev => prev + 1 : prev => prev);
  };

  // функция кнопки предыдущая страница
  const prevPage = () => {
    setCurrentPageNumber(currentPageNumber > 1 ? prev => prev - 1 : prev => prev);
  };
  // ===== пагинация =====


  // если в сторе нет массива, то получаем его и диспатчим в стор
  useEffect(() => {
    if (!equipmentOfOrganizatioList) {
      // сurrentOrganization[0] это uuid выбранной организации для построения списка ее оборудования
      dispatch(fetchEquipmentList(сurrentOrganization[0]));
    }

    // сортировка массива оборудования по "efointid" (возможно не понадобится)
    if (equipmentOfOrganizatioList && status === 'success') {
      // сортируем  массив по ключу "id" (по порядковому номеру поля)
      // setEquipmentOfOrganizatioListToFilter(Array.from(equipmentOfOrganizatioList).sort((a, b) => {
      //   return (+a.efointid > +b.efointid) - 0.5;
      // не сортируем  массив по ключу "id" (по порядковому номеру поля) 19092024 убрана сортировка
      setEquipmentOfOrganizatioListToFilter(equipmentOfOrganizatioList);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, equipmentOfOrganizatioList]);

  // получаем фиксированный и изменяемый массивы для фильтрации и очистки
  useEffect(() => {
    // установка названия организации, оборудование которой отображается в списке
    setEquipTitle(сurrentOrganization[1]);

    setLetEquipmentList(equipmentOfOrganizatioListToFilter);
    setConstEquipmentList(equipmentOfOrganizatioListToFilter);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [equipmentOfOrganizatioListToFilter]);


  // функция очистки фильтров и возврат к исходному списку
  const clearFilter = () => {
    setFilterForId('');
    setFilterForStatus('');
    setFilterForNumber('');
    setFilterForEquip('');
    setFilterForManufacture('');
    setFilterForBrand('');
    setFilterForProductDate('');
    setFilterForLaunchDate('');

    setLetEquipmentList(constEquipmentList);
  };

  // функция поиска по id
  const searchId = (e) => {
    setFilterForId(e.target.value);
    let newListEquipment = [];

    setFilterForStatus('');
    setFilterForNumber('');
    setFilterForEquip('');
    setFilterForManufacture('');
    setFilterForBrand('');
    setFilterForProductDate('');
    setFilterForLaunchDate('');

    constEquipmentList.forEach(item => {
      if (item.efouid.includes(e.target.value)) {
        newListEquipment.push(item);
      }
    });

    setLetEquipmentList(newListEquipment);

    setCurrentPageNumber(1);
  };

  // функция поиска по статусу
  const searchStatus = (e) => {
    setFilterForStatus(e.target.value);
    let newListEquipment = [];

    setFilterForId('');
    setFilterForNumber('');
    setFilterForEquip('');
    setFilterForManufacture('');
    setFilterForBrand('');
    setFilterForProductDate('');
    setFilterForLaunchDate('');

    constEquipmentList.forEach(item => {
      if (item.status.includes(e.target.value)) {
        newListEquipment.push(item);
      }

      if (e.target.value === 'Все') {
        newListEquipment.push(item);
      }
    });

    setLetEquipmentList(newListEquipment);

    setCurrentPageNumber(1);
  };

  // функция поиска по номеру
  const searchNumber = (e) => {
    setFilterForNumber(e.target.value);
    let newListEquipment = [];

    setFilterForId('');
    setFilterForStatus('');
    setFilterForEquip('');
    setFilterForManufacture('');
    setFilterForBrand('');
    setFilterForProductDate('');
    setFilterForLaunchDate('');

    constEquipmentList.forEach(item => {
      if (item.factorynumber.includes(e.target.value)) {
        newListEquipment.push(item);
      }
    });

    setLetEquipmentList(newListEquipment);

    setCurrentPageNumber(1);
  };

  // функция поиска по типу оборудования
  const searchEquip = (e) => {
    setFilterForEquip(e.target.value);
    let newListEquipment = [];

    setFilterForId('');
    setFilterForStatus('');
    setFilterForNumber('');
    setFilterForManufacture('');
    setFilterForBrand('');
    setFilterForProductDate('');
    setFilterForLaunchDate('');

    constEquipmentList.forEach(item => {
      if (item.typeOfEquipment.includes(e.target.value)) {
        newListEquipment.push(item);
      }

      if (e.target.value === 'Все') {
        newListEquipment.push(item);
      }
    });

    setLetEquipmentList(newListEquipment);

    setCurrentPageNumber(1);
  };

  // функция поиска по производителю
  const searchManufacture = (e) => {
    setFilterForManufacture(e.target.value);
    let newListEquipment = [];

    setFilterForId('');
    setFilterForStatus('');
    setFilterForNumber('');
    setFilterForEquip('');
    setFilterForBrand('');
    setFilterForProductDate('');
    setFilterForLaunchDate('');

    constEquipmentList.forEach(item => {
      if (item.manufacturer.manufacturername.includes(e.target.value)) {
        newListEquipment.push(item);
      }

      if (e.target.value === 'Все') {
        newListEquipment.push(item);
      }
    });

    setLetEquipmentList(newListEquipment);

    setCurrentPageNumber(1);
  };

  // функция поиска по марке оборудования (брэнд)
  const searchBrand = (e) => {
    setFilterForBrand(e.target.value);
    let newListEquipment = [];

    setFilterForId('');
    setFilterForStatus('');
    setFilterForNumber('');
    setFilterForEquip('');
    setFilterForManufacture('');
    setFilterForProductDate('');
    setFilterForLaunchDate('');

    constEquipmentList.forEach(item => {
      if (item.brand.brandname.includes(e.target.value)) {
        newListEquipment.push(item);
      }
    });

    setLetEquipmentList(newListEquipment);

    setCurrentPageNumber(1);
  };

  // функция поиска по дате производства
  const searchProductDate = (e) => {
    setFilterForProductDate(e.target.value);
    let newListEquipment = [];

    setFilterForId('');
    setFilterForStatus('');
    setFilterForNumber('');
    setFilterForEquip('');
    setFilterForManufacture('');
    setFilterForBrand('');
    setFilterForLaunchDate('');

    constEquipmentList.forEach(item => {
      if (item.dateofacceptanceforwarranty.includes(e.target.value)) {
        newListEquipment.push(item);
      }
    });

    setLetEquipmentList(newListEquipment);

    setCurrentPageNumber(1);
  };

  // функция поиска по дате ввода в эксплуатацию
  const searchLaunchDate = (e) => {
    setFilterForLaunchDate(e.target.value);
    let newListEquipment = [];

    setFilterForId('');
    setFilterForStatus('');
    setFilterForNumber('');
    setFilterForEquip('');
    setFilterForManufacture('');
    setFilterForBrand('');
    setFilterForProductDate('');

    constEquipmentList.forEach(item => {
      if (item.dateofcommissioning.includes(e.target.value)) {
        newListEquipment.push(item);
      }
    });

    setLetEquipmentList(newListEquipment);

    setCurrentPageNumber(1);
  };

  // возвращаемся на вкладку "База клиентов" и очищаем список оборудования организации в store
  const btnBackHandler = () => {
    navigate('/superlift/clientbase');
    dispatch(removeEquipmentList());
  };


  return (
    <>
      <div className={st.deptclbase}>
        {/* верхняя строка с заголовком и кнопками */}
        <div className={st.title_and_btn}>
          {/* блок с кнопкой обратно и заголовком */}
          <div className={st.wrap_title}>
            <button onClick={btnBackHandler}>
              <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 17L1 9L9 1" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </button>

            <h3 className={st.title}>Оборудование {equipTitle}</h3>
          </div>

          {/* блок кнопок добавить оборудование и фильтры */}
          <div className={st.btn_group}>
            <button className={st.btn_add_news} onClick={() => setModalAdd(!modalAdd)}>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.5 1.75v16.5M17.75 10H1.25" stroke="#fff" strokeWidth="2" strokeLinecap="round"
                  strokeLinejoin="round" />
              </svg>
              Добавить оборудование</button>

            <button
              className={st.btn_filter}
              onClick={() => { setFilterListShow(!filterListShow) }}>
              Фильтры
              <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.5 6.75h21M5.25 12h13.5m-9 5.25h4.5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </button>
          </div>
        </div>

        {/* панель заголовков */}
        <ul className={st.table_header}>
          <li className={st.header_item}>ID</li>
          <li className={st.header_item}>Статус</li>
          <li className={st.header_item}>Номер</li>
          <li className={st.header_item}>Оборудование</li>
          <li className={st.header_item}>Производитель</li>
          <li className={st.header_item}>Марка</li>
          <li className={st.header_item}>Дата&#160;производства</li>
          <li className={st.header_item}>Дата&#160;запуска</li>
          <li className={st.header_item}>Хар-ки</li>
          <li className={st.header_item}>Паспорт</li>
          <li className={st.header_item}>Детали</li>
          <li className={st.header_item}>Адрес</li>
          <li className={st.header_item}>Платежи</li>
          <li className={st.header_item}><IconSetting /></li>
        </ul>

        {/* панель фильтров */}
        <ul className={!filterListShow ? st.filter_list_hidden : st.filter_list}>

          {/* по id */}
          <li className={st.filter_list_item}>
            <div className={st.item_name}>
              <p className={st.item_name_p}>ID</p>
              <input
                className={st.filter_input}
                type='text'
                name='filterForId'
                value={filterForId}
                onChange={searchId} />
              <span
                className={st.clear_input}
                onMouseDown={clearFilter}
              >
                <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 1L7 7M7 1L1 7" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
                </svg>
              </span>
            </div>
          </li>

          {/* по статусу */}
          <li className={st.filter_list_select}>
            <div className={st.item_name}>
              <p className={st.item_name_p}>Статус</p>
              <select
                className={st.filter_select}
                name='filterForStatus'
                value={filterForStatus}
                onChange={searchStatus}>

                <option value=''>Все</option>
                <option value='Entered'>Заведен</option>
                <option value='UnderWarranty'>На гарантии</option>
              </select>
            </div>
          </li>

          {/* по номеру */}
          <li className={st.filter_list_item}>
            <div className={st.item_name}>
              <p className={st.item_name_p}>Номер</p>
              <input
                className={st.filter_input}
                type='text'
                name='filterForNumber'
                value={filterForNumber}
                onChange={searchNumber} />
              <span
                className={st.clear_input}
                onMouseDown={clearFilter}
              >
                <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 1L7 7M7 1L1 7" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
                </svg>
              </span>
            </div>
          </li>

          {/* по оборудованию */}
          <li className={st.filter_list_select}>
            <div className={st.item_name}>
              <p className={st.item_name_p}>Оборудование</p>
              <select
                className={st.filter_select}
                name='filterForEquip'
                value={filterForEquip}
                onChange={searchEquip}>

                <option value=''>Все</option>
                <option value='Elevator'>Лифт</option>
                <option value='Travolator'>Травалатор</option>
                <option value='Escalator'>Эскалатор</option>
              </select>
            </div>
          </li>

          {/* по производителю */}
          <li className={st.filter_list_select}>
            <div className={st.item_name}>
              <p className={st.item_name_p}>Производитель</p>
              <select
                className={st.filter_select}
                name='filterForManufacture'
                value={filterForManufacture}
                onChange={searchManufacture}>

                <option value=''>Все</option>
                <option value='BLT'>BLT</option>
                <option value='KONE'>KONE</option>
                <option value='OTIS'>OTIS</option>
              </select>
            </div>
          </li>

          {/* по марке */}
          <li className={st.filter_list_item}>
            <div className={st.item_name}>
              <p className={st.item_name_p}>Марка</p>
              <input
                className={st.filter_input}
                type='text'
                name='filterForBrand'
                value={filterForBrand}
                onChange={searchBrand} />
              <span
                className={st.clear_input}
                onMouseDown={clearFilter}
              >
                <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 1L7 7M7 1L1 7" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
                </svg>
              </span>
            </div>
          </li>

          {/* по дате производства */}
          <li className={st.filter_list_item}>
            <div className={st.item_name}>
              <p className={st.item_name_p}>Дата производства</p>
              <input
                className={st.filter_input}
                type='date'
                name='filterForProductDate'
                value={filterForProductDate}
                onChange={searchProductDate} />
              <span
                className={st.clear_input}
                onMouseDown={clearFilter}
              >
                <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 1L7 7M7 1L1 7" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
                </svg>
              </span>
            </div>
          </li>

          {/* по дате запуска */}
          <li className={st.filter_list_item}>
            <div className={st.item_name}>
              <p className={st.item_name_p}>Дата запуска</p>
              <input
                className={st.filter_input}
                type='date'
                name='filterForLaunchDate'
                value={filterForLaunchDate}
                onChange={searchLaunchDate} />

              <span
                className={st.clear_input}
                onMouseDown={clearFilter}
              >
                <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 1L7 7M7 1L1 7" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
                </svg>
              </span>
            </div>
          </li>
        </ul>

        {/* формирование списка оборудования */}
        <ul className={st.clbase_list}>
          {equipmentCurrentList.length !== 0 ? equipmentCurrentList.map((item) =>
            <EquipmentPreview
              key={uuidv4()}

              сurrentOrganization={сurrentOrganization}
              efouid={item.efouid}
              uuid={item.uuid}
              equid={item.equid}
              status={item.status}

              factorynumber={item.factorynumber ? item.factorynumber : ''}
              typeOfEquipment={item.typeOfEquipment}
              manufacturername={item.manufacturer.manufacturername}
              brandname={item.brand.brandname}

              cabintype={item.cabintype}
              numberofstops={item.numberofstops}

              purpose={item.purpose}
              speed={item.speed}
              loadcapacity={item.loadcapacity}
              dateofacceptanceforwarranty={
                // item.dateofacceptanceforwarranty?.slice(0, -9).split('-').reverse().join('-')
                item.dateofacceptanceforwarranty?.slice(0, -9)
              }
              dateofcommissioning={
                // item.dateofcommissioning?.slice(0, -9).split('-').reverse().join('-')
                item.dateofcommissioning?.slice(0, -9)
              }
              passport={item.passportlink || ''}

              installationaddress={item.installationaddress}

              partsList={item.partsList || {}}

              schedule={item.schedule || []}

              setCurrentPageNumber={() => setCurrentPageNumber(1)}
            />
          ) : <p className={st.notification}>Оборудование данной организации не найдено!</p>}
        </ul>

        {/* пагинация */}
        <div className={st.pagination}>
          <button
            className={statementsQuantity <= totalStatementsQuantity ? st.pagination_btn_prev : st.hidden}
            onClick={prevPage}>
          </button>
          {
            pages.map((item) => {
              return (
                <button
                  key={uuidv4()}
                  onClick={() => setCurrentPageNumber(item)}
                  className={
                    pages.length === 1 ? st.hidden :
                      item === currentPageNumber
                        ? st.pagination_item_active
                        : st.pagination_item
                  }>
                  {item}
                </button>
              );
            })
          }
          <button
            className={statementsQuantity <= totalStatementsQuantity ? st.pagination_btn_next : st.hidden}
            onClick={nextPage}>
          </button>
        </div>
      </div>

      {/* оверлей с окном добавления оборудования */}
      <Overlay className={!modalAdd ? st.overlay_hide : st.overlay_show}>
        <EquipmentAdd closeModal={() => setModalAdd(false)} />
      </Overlay>
    </>
  )
};
